<template>
  <div id="careerAccording">
    <ul class="accordion" role="tablist" multiselectable="true">
      <li
        v-for="(career, index) in list"
        :key="index"
      >
        <div class="panel-title">
          <a :href="'#panel-' + career.id" aria-expanded="false" :aria-controls="'panel-' + career.id" :id="'control-panel-' + career.id" aria-hidden="true">
            <title>{{career.title}}</title>
            <small>{{dateToString(career.createAt)}}</small>
            <span class="icon">+</span>
          </a>
        </div>
        <div class="panel-content" :id="'panel-' + career.id" :aria-labelledby="'control-panel-' + career.id" role="tabpanel">
          <dl v-if="career.position">
            <dt>{{ $t('ca.class') }}</dt>
            <dd
              v-for="(position, index) in career.position.split('\n')"
              :key="index"
            >{{position}}</dd>
          </dl>
          <dl v-if="career.job">
            <dt>{{ $t('ca.role') }}</dt>
            <dd
              v-for="(job, index) in career.job.split('\n')"
              :key="index"
            >{{job}}</dd>
          </dl>
          <dl v-if="career.employment">
            <dt>{{ $t('ca.type') }}</dt>
            <dd
              v-for="(employment, index) in career.employment.split('\n')"
              :key="index"
            >{{employment}}</dd>
          </dl>
          <dl v-if="career.qualification">
            <dt>{{ $t('ca.condition') }}</dt>
            <dd
              v-for="(qualification, index) in career.qualification.split('\n')"
              :key="index"
            >{{qualification}}</dd>
          </dl>
          <dl v-if="career.preferentialTreatment">
            <dt>{{ $t('ca.special') }}</dt>
            <dd
              v-for="(preferentialTreatment, index) in career.preferentialTreatment.split('\n')"
              :key="index"
            >{{preferentialTreatment}}</dd>
          </dl>
          <dl v-if="career.workingCondition">
            <dt>{{ $t('ca.environment') }}</dt>
            <dd
              v-for="(workingCondition, index) in career.workingCondition.split('\n')"
              :key="index"
            >{{workingCondition}}</dd>
          </dl>
          <dl v-if="career.screeningProcess">
            <dt>{{ $t('ca.form') }}</dt>
            <dd
              v-for="(screeningProcess, index) in career.screeningProcess.split('\n')"
              :key="index"
            >{{screeningProcess}}</dd>
          </dl>
          <dl v-if="career.requiredDocuments">
            <dt>{{ $t('ca.submit') }}</dt>
            <dd
              v-for="(requiredDocuments, index) in career.requiredDocuments.split('\n')"
              :key="index"
            >{{requiredDocuments}}</dd>
          </dl>
          <dl v-if="career.applyWay">
            <dt>{{ $t('ca.apply') }}</dt>
            <dd
              v-for="(applyWay, index) in career.applyWay.split('\n')"
              :key="index"
            >{{applyWay}}</dd>
          </dl>
          <dl v-if="career.applyTerm">
            <dt>{{ $t('ca.term') }}</dt>
            <dd
              v-for="(applyTerm, index) in career.applyTerm.split('\n')"
              :key="index"
            >{{applyTerm}}</dd>
          </dl>
          <dl v-if="career.applyUrl">
            <dt>{{ $t('ca.reflink') }}</dt>
            <dd
              v-for="(applyUrl, index) in career.applyUrl.split('\n')"
              :key="index"
            ><a :href="applyUrl" target="_blank">{{applyUrl}}</a></dd>
          </dl>
          <dl v-if="career.etc">
            <dt>{{ $t('ca.etc') }}</dt>
            <dd
              v-for="(etc, index) in career.etc.split('\n')"
              :key="index"
            >{{etc}}</dd>
          </dl>
          <div class="btn-cen">
            <a :href="'mailto:hr.admin@iacryl.com?subject=[입사지원 | ' + career.title + ']'" class="button button-primary">지원하기</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { drawAccor, dateToString } from '@/assets/js/util.js'
import $ from 'jquery'

export default {
  props: [
    'list'
  ],
  data() {
    return {
      selectedId: -1
    }
  },
  methods: {
    dateToString (date) { return dateToString(date, "yyyy.mm.dd") }
  },
  mounted: function () {
    this.$nextTick(() => {
      drawAccor()
      // $('.panel-content').hide()
      // document.querySelector('.panel-content').hide()
    })
  }
}
</script>
