<template>
  <!-- container -->
  <div id="container">
    <!-- 본문 -->
    <div id="contents" class="carrer-con">
      <div class="carrer">
        <!-- 서브 배너 -->
        <section class="subTopArea inner-fixed">
          <span v-html="$t('views.career.banner')"></span>
          <div class="btn-cen">
          <a @click="scrollTo('.careerNotice')" class="button-go-carrer">{{ $t('views.career.notice') }} <label>{{careerList.length}}</label></a>
          </div>
        </section>
        
        <section class="carrerImg">
          <div class="areaBox inner-fixed">
            <img src="@/assets/images/image_recruit-1.png" v-bind:srcset="recruitImges_2x + ' 2x,' + recruitImges_3x + ' 3x'">
          </div>
        </section>

        <section class="collaborate">
          <div class="areaBox inner-fixed">
            <h3 v-html="$t('views.career.subtitle')"></h3>
            <ul>
              <li>
                <title>Accountability</title>
                <small v-html="$t('views.career.accountability')"></small>
              </li>
              <li>
                <title>Creativity</title>
                <small v-html="$t('views.career.creativity')"></small>
              </li>
              <li>
                <title>Respect</title>
                <small v-html="$t('views.career.respect')"></small>
              </li>
              <li>
                <title>Yearning</title>
                <small v-html="$t('views.career.yearning')"></small>
              </li>
              <li>
                <title>Liberty</title>
                <small v-html="$t('views.career.liberty')"></small>
              </li>
            </ul>
          </div>
        </section>

        <section :class="`${$_gGet_Locale()} benefits`">
          <div class="areaBox inner-fixed">
            <h3 v-html="$t('views.career.benefit01')"></h3>
            <ul class="owl-carousel owl-benefits">
              <li>
                <p v-html="$t('views.career.benefit02')"></p>
              </li>
              <li>
                <p v-html="$t('views.career.benefit03')"></p>
              </li>
              <li>
                <p v-html="$t('views.career.benefit04')"></p>
              </li>
              <li>
                <p v-html="$t('views.career.benefit05')"></p>
              </li>
              <li v-if="!isMobile">
                <title>BENEFITS</title>
              </li>
              <li>
                <p v-html="$t('views.career.benefit06')"></p>
              </li>
              <li>
                <p v-html="$t('views.career.benefit07')"></p>
              </li>
              <li>
                <p v-html="$t('views.career.benefit08')"></p>
              </li>
              <li>
                <p v-html="$t('views.career.benefit09')"></p>
              </li>
            </ul>
          </div>
        </section>

        <section class="careerGallery">
          <div class="inner-fixed">
            <ul class="owl-carousel owl-gallery">
              <li> <img src="@/assets/images/Image_Recruit-Banner-1.png" :alt="$t('views.career.benefit10')"><span>{{ $t('views.career.benefit10') }}</span></li>
              <li><img src="@/assets/images/Image_Recruit-Banner-2.png" :alt="$t('views.career.benefit10')"><span>{{ $t('views.career.benefit10') }}</span></li>
              <li><img src="@/assets/images/Image_Recruit-Banner-3.png" :alt="$t('views.career.benefit10')"><span>{{ $t('views.career.benefit10') }}</span></li>
              <li><img src="@/assets/images/Image_Recruit-Banner-4.png" :alt="$t('views.career.benefit10')"><span>{{ $t('views.career.benefit10') }}</span></li>
              <li><img src="@/assets/images/Image_Recruit-Banner-5.png" :alt="$t('views.career.benefit10')"><span>{{ $t('views.career.benefit10') }}</span></li>
              <li><img src="@/assets/images/Image_Recruit-Banner-6.png" :alt="$t('views.career.benefit10')"><span>{{ $t('views.career.benefit10') }}</span></li>
            </ul>
            <div class="slider-counter"></div>
          </div>
        </section>

        <section class="careerNotice">
          <div class="areaBox inner-fixed">
            <h3>{{$t('views.career.career')}}</h3>
            <CareerAccording v-if="Object.keys(careerList).length > 0" :list="careerList" />
          </div>
        </section>

      </div>
    </div>
    <!-- 본문 -->
  </div>
  <!-- //container -->
</template>

<script>
import CareerAccording from '@/components/According/CareerAccording.vue'
import { owlCarousel, scrollTo } from '@/assets/js/util.js'
import instance from '@/assets/js/api.js'
import jQuery from 'jquery'

export default {
  components: {
    CareerAccording
  },
  data () {
    return {
      recruitImges_2x: require('@/assets/images/image_recruit-1.png'),
      recruitImges_3x: require('@/assets/images/image_recruit-1.png'),
      careerList: [],
      isMobile: false,
    }
  },
  methods: {
    getCareerList () {
      const url = '/career/list'
      const method = 'get'

      let data = instance({
        url,
        method,
      })
      data.then(responseData => {
        const {
          result,
          response
        } = responseData
        if (result) {
          const {
            list
          } = response

          this.careerList = list
        }
      })
    },
    scrollTo(eleStr) { scrollTo(eleStr) },
  },
  created: function() {
    this.getCareerList()
  },

  mounted: function() {
    if (document.body.clientWidth < 769) this.isMobile = true
    if (this.isMobile) {
      let benefitsOptions = {
        loop: false,
        items: 1,
        margin: 20,
        responsive: {
          0: {items: 1, stagePadding: 40},
          768: {items: 1, stagePadding: 100}
        }
      }
      owlCarousel('.owl-benefits', benefitsOptions)
    }

    jQuery(function ($) {
      $('.owl-gallery').on('initialized.owl.carousel changed.owl.carousel', function (e) {
        if (!e.namespace) {
          return
        }
        var carousel = e.relatedTarget
        $('.slider-counter').text(carousel.relative(carousel.current()) + 1 + ' | ' + carousel.items().length)
      })
      
      let options = {
        items: 1,
        loop: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 2000,
      }
      owlCarousel('.owl-gallery', options)
    })
  },
  updated: function() {
    if('careerId' in this.$route.params) {
      this.$nextTick(() => {
        const { careerId } = this.$route.params
        jQuery(function ($) {
          $(`#control-panel-${careerId}`).click()
        })
        scrollTo(`#panel-${careerId}`)
      })
    }
  }
}
</script>
